import React from "react";
import { Button, Layout, Menu, Popover, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import LogoContainer from "./LogoContainer/LogoContainer";

import styles from "./Page.module.css";
import config from "../../config";
import { useUser } from "./User/User.Context";
import {
  LucideLayers,
  MessageCircle,
  Settings,
  File,
  GitBranch,
  Share2,
  Users,
  UserCircle2,
  Ellipsis,
  CircleUserIcon,
  Shield,
  FileText,
} from "lucide-react";
import LanguageSwitch from "./Language/LanguageSwitch";
import { useTranslation } from "react-i18next";

const { Sider, Content } = Layout;

interface PropType {
  children: React.ReactNode;
}

export const Page: React.ElementType = ({ children }: PropType) => {
  const navigate = useNavigate();
  const { logout, user } = useUser();
  const { t } = useTranslation("menu");

  let items = [];

  if (config.settings.dashboardOn && user.role === "SUPERADMIN") {
    items.push({
      key: config.routes.dashboard,
      icon: <LucideLayers width={20} />,
      label: t("dashboard"),
    });
  }

  items = [
    ...items,
    {
      key: config.routes.agent,
      icon: <MessageCircle width={20} />,
      label: t("agent"),
    },
    {
      key: config.routes.account,
      icon: <CircleUserIcon width={20} />,
      label: t("myAccount"),
    },
    {
      key: config.routes.terms,
      icon: <FileText width={20} />,
      label: t("terms"),
    },
    {
      key: config.routes.privacy,
      icon: <Shield width={20} />,
      label: t("privacy"),
    },
  ];

  if (user.role !== "USER") {
    items.push(
      {
        key: config.routes.files,
        icon: <File width={20} />,
        label: t("files"),
      },
      {
        key: config.routes.settings,
        icon: <Settings width={20} />,
        label: t("settings"),
      }
    );
  }

  if (config.settings.workflowOn && user.role === "SUPERADMIN") {
    items.push({
      key: config.routes.workflow,
      icon: <GitBranch width={20} />,
      label: t("workflow"),
    });
  }

  if (config.settings.apiKeysOn && user.role === "SUPERADMIN") {
    items.push({
      key: config.routes.apiKeys,
      icon: <Share2 width={20} />,
      label: t("apiKeys"),
    });
  }

  if (config.settings.usersOn && user.role === "SUPERADMIN") {
    items.push({
      key: config.routes.user,
      icon: <Users width={20} />,
      label: t("users"),
    });
  }

  return (
    <Layout hasSider>
      <Sider collapsedWidth={0} breakpoint="lg">
        <Row justify="center" className={styles.logo}>
          <LogoContainer logoSrc={config.logo} />
        </Row>
        <Row className={styles.userRow}>
          <LanguageSwitch />
        </Row>
        <Row align="middle" className={styles.userRow}>
          <UserCircle2 color="white" />
          <Typography.Text className={styles.user}>
            {user.firstName.length > 10
              ? user.firstName.substring(0, 8) + "..."
              : user.firstName}
          </Typography.Text>
          <Popover
            placement="top"
            arrow={true}
            content={
              <Button onClick={logout} type="link">
                {t("logout")}
              </Button>
            }
          >
            <Button type="link" icon={<Ellipsis />} />
          </Popover>
        </Row>
        <Menu
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
          theme="dark"
          items={items}
          activeKey="/settings"
          className={styles.menu}
          onClick={({ key }) => {
            navigate(key);
          }}
        />
      </Sider>
      <Layout>
        <Content className={styles.content}>
          <div className={styles.bg} />
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Page;
