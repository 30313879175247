import React from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Form, Input, Layout, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import cs from "classnames";
import styles from "./login.module.css";
import config from "../../../config";
import { useLogin } from "./useLogin";
import { useTranslation } from "react-i18next";
import LogoContainer from "../../common/LogoContainer/LogoContainer";

export const Login: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { login, loading } = useLogin();
  const { t: tCommon } = useTranslation("common");
  const { t: tLogin } = useTranslation("login");

  return (
    <Layout className={styles.bg}>
      <Space
        direction="vertical"
        className={cs(["full-width center-page"])}
        size="large"
      >
        <Row justify="center">
          <LogoContainer size="large" logoSrc={config.logo} />
        </Row>
        <Row justify="center" align="middle">
          <Form
            disabled={loading}
            layout="vertical"
            form={form}
            name="login"
            onFinish={login}
            className={styles.formWidth}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label={tCommon("email")}
              rules={[
                {
                  type: "email",
                  message: tLogin("validation.emailFormat"),
                },
                {
                  required: true,
                  message: tLogin("validation.email"),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label={tCommon("password")}
              rules={[
                {
                  required: true,
                  message: tLogin("validation.password"),
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Row>
              <Typography.Paragraph>
                {tLogin("dontHaveAccount")}
                <Button
                  type="link"
                  onClick={() => {
                    navigate(config.routes.register);
                  }}
                >
                  {tCommon("create")}
                </Button>
              </Typography.Paragraph>
            </Row>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {tLogin("login")}
            </Button>
          </Form>
        </Row>
      </Space>
    </Layout>
  );
};
