import React from "react";

import SpinnerImg from "./spinner.svg";
import style from "./Spinner.module.css";

export const Spinner: React.FC = () => {
  return (
    <div className={style.spinnerContainer}>
      <img
        className={style.spinner}
        src={SpinnerImg as unknown as string}
        height={26}
        width={26}
        alt="spinner"
      />
    </div>
  );
};
