import { useCallback, useState } from "react";
import config from "../../../config";
import { message } from "antd";
import { useFetch } from "use-http";

interface Settings {
  model: string;
  vectorDatabase: string;
  systemPrompt: string;
}

interface SettingsReturnType {
  loading: boolean;
  save: (data: Settings) => Promise<void>;
  getSettings: () => void;
  settings: Settings | undefined;
}

export default function useSettings(): SettingsReturnType {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Settings>();
  const { get, response, post } = useFetch(
    `${config.api}${config.apiRoutes.settings}`
  );

  const getSettings = useCallback(async () => {
    setLoading(true);
    try {
      const result = await get();
      if (response.ok) {
        setSettings(result);
      }
    } catch (ex) {
      //
    }
    setLoading(false);
  }, [get, response.ok]);

  const save = useCallback(
    async (data: { model: string; vectorDatabase: string }) => {
      setLoading(true);
      try {
        await post(data);
        if (response.ok) {
          message.success("Data has been updated");
          setLoading(false);
          return;
        }
        message.error(
          "An error appeared while saving! Please try again later."
        );
      } catch (ex) {
        console.log(ex);
        message.error(
          "An error appeared while saving! Please try again later."
        );
      }
      setLoading(false);
    },
    [post, response.ok]
  );

  return {
    loading,
    save,
    getSettings,
    settings,
  };
}
