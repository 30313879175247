import React from "react";
import { Typography, Table } from "antd";

import s from "./privacy.module.css";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

export const Privacy: React.FC = () => {
  const { t } = useTranslation("privacy");

  const renderTable = (
    table: Array<{ title: string; description: string }>
  ) => {
    const dataSource = table.map((row, index) => ({
      key: index,
      title: <ReactMarkdown>{row.title}</ReactMarkdown>,
      description: <ReactMarkdown>{row.description}</ReactMarkdown>,
    }));

    const columns = [
      {
        dataIndex: "title",
        key: "title",
      },
      {
        dataIndex: "description",
        key: "description",
      },
    ];

    return (
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    );
  };

  const renderContent = (
    content: Array<{
      title: string;
      description: string;
      content?: Array<any>;
    }>
  ) => {
    return content.map((item, index) => (
      <div key={index} className={s.item}>
        <Typography.Title level={4}>
          <ReactMarkdown>{item.title}</ReactMarkdown>
        </Typography.Title>
        <Typography.Paragraph>
          <ReactMarkdown>{item.description}</ReactMarkdown>
        </Typography.Paragraph>
        {item.content && (
          <>
            {item.content.some((subItem) => subItem.table)
              ? item.content.map((subItem, subIndex) => (
                  <div key={`${index}-${subIndex}`}>
                    {subItem.table && renderTable(subItem.table)}
                    {!subItem.table && (
                      <div>
                        <Typography.Title level={5}>
                          <ReactMarkdown>{subItem.title}</ReactMarkdown>
                        </Typography.Title>
                        <Typography.Paragraph>
                          <ReactMarkdown>{subItem.description}</ReactMarkdown>
                        </Typography.Paragraph>
                      </div>
                    )}
                  </div>
                ))
              : renderContent(item.content)}
          </>
        )}
      </div>
    ));
  };

  return (
    <div className={s.page}>
      <Typography.Title level={2}>
        {" "}
        <ReactMarkdown>{t("title")}</ReactMarkdown>
      </Typography.Title>
      <Typography.Paragraph>
        <ReactMarkdown>{t("info")}</ReactMarkdown>
      </Typography.Paragraph>
      {renderContent(
        t("content", { returnObjects: true }) as Array<{
          title: string;
          description: string;
          content?: Array<any>;
        }>
      )}
      <Typography.Paragraph>{t("version")}</Typography.Paragraph>
    </div>
  );
};
