import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Typography } from "antd";

import s from "./terms.module.css";

export const Terms: React.FC = () => {
  const { t } = useTranslation("terms");
  return (
    <div className={s.page}>
      <Typography.Title level={2}>{t("title")}</Typography.Title>
      <Typography.Paragraph>
        <ReactMarkdown>{t("info")}</ReactMarkdown>
      </Typography.Paragraph>
      {(
        t("content", { returnObjects: true }) as Array<{
          title: string;
          description: string;
        }>
      ).map((item, index) => (
        <div key={index} className={s.item}>
          <Typography.Title level={4}>{item.title}</Typography.Title>
          <Typography.Paragraph>
            <ReactMarkdown>{item.description}</ReactMarkdown>
          </Typography.Paragraph>
        </div>
      ))}
      <Typography.Paragraph>{t("version")}</Typography.Paragraph>
    </div>
  );
};
