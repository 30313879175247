import React from "react";
import { Button, Card, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Page from "../../common/Page";
import config from "../../../config";
import WorkflowsList from "./WorkflowsList";

export const Workflow: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("workflow");

  return (
    <Page>
      <Space direction="vertical" className="full-width">
        <Card className="card">
          <Space direction="vertical" className="full-width">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                navigate(config.routes.workflowNew);
              }}
            >
              {t("createFlow")}
            </Button>
          </Space>
        </Card>
        <Card bordered title={t("workflows")}>
          <WorkflowsList />
        </Card>
      </Space>
    </Page>
  );
};
