import React from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Form, Input, Layout, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import cs from "classnames";
import styles from "./Code.module.css";
import config from "../../../config";
import { useLogin } from "../Login/useLogin";
import { useTranslation } from "react-i18next";

export const Code: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { validateCode, loadingCode } = useLogin();
  const { t: tLogin } = useTranslation("login");
  const { t: tCommon } = useTranslation("common");

  return (
    <Layout className={styles.bg}>
      <Space
        direction="vertical"
        className={cs(["full-width center-page"])}
        size="large"
      >
        <Row justify="center">
          <img alt="logo" src={config.logo} width={90} />
        </Row>
        <Row justify="center" align="middle">
          <Form
            layout="vertical"
            form={form}
            name="code"
            className={styles.formWidth}
            scrollToFirstError
            onFinish={() => {
              validateCode(form.getFieldValue("code"));
            }}
          >
            <Form.Item
              name="code"
              label={tLogin("insertCode")}
              rules={[
                {
                  required: true,
                  message: tLogin("validation.code"),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Row>
              <Typography.Paragraph>
                {tLogin("dontHaveAccount")}
                <Button
                  type="link"
                  onClick={() => {
                    navigate(config.routes.register);
                  }}
                >
                  {tCommon("create")}
                </Button>
              </Typography.Paragraph>
            </Row>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loadingCode}
            >
              {tCommon("submit")}
            </Button>
          </Form>
        </Row>
      </Space>
    </Layout>
  );
};
