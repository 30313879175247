export enum StatusStep {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface StepType {
  status: StatusStep;
  data: string;
  timestamp: Date;
  actionType: string;
  inputType: string;
  prompt: string;
  stepTitle: string;
}

export interface DataType {
  completed: boolean;
  createdAt: string;
  failed: boolean;
  workflowId: string;
  steps: StepType[];
  _id: string;
}
