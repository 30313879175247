import { useFetch } from "use-http";
import config from "../../../config";
import { useCallback } from "react";
import { message } from "antd";

interface FormData {
  email: string;
  firstName: string;
  password: string;
  lastName: string;
}

interface ReturnTypeRegister {
  register: (formData: FormData) => Promise<void>;
  loading: boolean;
}

export function useRegister(): ReturnTypeRegister {
  const { post, response, loading } = useFetch(
    `${config.api}${config.apiRoutes.register}`
  );
  const register = useCallback(
    async (formData: FormData) => {
      await post(formData);
      if (response.ok) {
        message.success("User registered successfully");
        return;
      }
      message.error("There was an error registering the user");
    },
    [post, response.ok]
  );
  return {
    register,
    loading,
  };
}
