export default {
  defaultPage: "/agent",
  logo: process.env.REACT_APP_LOGO || "logo_decalex.svg",
  languages: {
    defaultLang: "ro",
    available: "en",
  },
  routes: {
    home: "/",
    dashboard: "/dashboard",
    agent: "/agent",
    code: "/code",
    files: "/files",
    terms: "/terms",
    privacy: "/privacy",
    user: "/user",
    settings: "/settings",
    account: "/account",
    workflow: "/workflow",
    workflowNew: "/workflow/new",
    workflowHistory: "/workflow/:id/history",
    workflowResult: "/workflow/run/:id/result",
    workflowEdit: "/workflow/:id/edit",
    register: "/register",
    login: "/login",
    apiKeys: "/api-keys",
  },
  ws: process.env.REACT_APP_WS || "ws://localhost:3001",
  api: process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3001",
  settings: {
    workflowOn: process.env.REACT_APP_WORKFLOW_ON === "true",
    apiKeysOn: process.env.REACT_APP_APIKEYS_ON === "true",
    usersOn: process.env.REACT_APP_USERS_ON === "true",
    llamaOn: process.env.REACT_APP_LLAMA_ON === "true",
    dashboardOn: process.env.REACT_APP_DASHBOARD_ON === "true",
  },
  apiRoutes: {
    documents: "/chats/documents",
    apiKeys: "/apikeys",
    apiKeyDelete: "/apikeys/:id",
    workflow: "/workflow",
    workflowRun: "/workflow/run",
    workflowRunById: "/workflow/run/:id",
    workflowDelete: "/workflow/:id",
    workflowById: "/workflow/:id",
    settings: "/chats/settings",
    register: "/users",
    login: "/users/login",
    usersValidateCode: "/users/validate/:validationId",
    users: "/users",
    userUpdateMe: "/users/me",
    userUpdateMePassword: "/users/me/password",
    userUpdate: "/users/:id",
    deleteUser: "/users/:email",
    chatsTrain: "/chats/train",
    chatsById: "/chats/:id",
    chats: "/chats",
    workflowStats: "/workflow/stats",
    workflowHistory: "/workflow/history/:id",
    me: "/users/me",
    like: "/chats/like/:id",
    dislike: "/chats/dislike/:id",
  },
};
