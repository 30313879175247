import React from "react";
import cs from "classnames";
import styles from "./LogoContainer.module.css";

interface LogoContainerProps {
  size?: "extra-small" | "small" | "default" | "large";
  logoSrc: string;
}

const LogoContainer: React.FC<LogoContainerProps> = ({
  size = "default",
  logoSrc,
}: {
  size?: string;
  logoSrc: string;
}) => {
  return (
    <div className={cs([styles["logo-container"], styles[`logo-${size}`]])}>
      <img src={logoSrc} alt="logo" className={styles["logo-image"]} />
    </div>
  );
};

export default LogoContainer;
