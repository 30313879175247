import {
  Alert,
  Button,
  Card,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import Page from "../../common/Page";
import useApiKey from "./useApiKey";
import dayjs from "dayjs";
import { PlusCircle, Trash } from "lucide-react";

export const ApiKey: React.FC = () => {
  const { loading, apiKeys, post, lastKey, get, deleteApiKey } = useApiKey();

  const columns = [
    {
      title: "API Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return dayjs(createdAt).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row: { _id: string }) => (
        <Space>
          <Popconfirm
            title="Delete the API Key"
            description="Are you sure to delete this API Key?"
            onConfirm={async () => {
              deleteApiKey(row._id);
            }}
            okText="Yes"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
            cancelText="No"
          >
            <Button icon={<Trash width={12} height={12} />} size="large">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Page>
      <Card title="API Keys">
        <Space direction="vertical" className="full-width">
          <Button
            size="large"
            icon={<PlusCircle width={12} height={12} />}
            onClick={async () => {
              await post();
              await get();
            }}
            disabled={loading}
            loading={loading}
          >
            Generate new
          </Button>
          {lastKey ? (
            <Alert
              message={
                <Typography.Paragraph className="no-margin" copyable>
                  {lastKey}
                </Typography.Paragraph>
              }
              type="success"
            />
          ) : (
            ""
          )}
          <Table dataSource={apiKeys} columns={columns} loading={loading} />
        </Space>
      </Card>
    </Page>
  );
};
