import React from "react";
import { Card, Form, Input, Modal, Select, Space, Table } from "antd";
import Page from "../../common/Page";
import useUser from "./useUser";

export const Users: React.FC = () => {
  const {
    dataSource,
    columns,
    isEditUser,
    setIsEditUser,
    editForm,
    loadingUpdateUser,
    saveEditedUser,
  } = useUser();
  return (
    <Page>
      <Space direction="vertical" className="full-width">
        <Modal
          confirmLoading={loadingUpdateUser}
          title="Edit user"
          open={isEditUser}
          onOk={() => {
            saveEditedUser();
          }}
          onCancel={() => {
            setIsEditUser(false);
          }}
          okButtonProps={{
            size: "large",
          }}
          cancelButtonProps={{
            size: "large",
          }}
          okText="Save"
        >
          <Form layout="vertical" form={editForm}>
            <Form.Item name="id">
              <Input type="hidden" name="id" />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select size="large">
                <Select.Option value="0">Active</Select.Option>
                <Select.Option value="9">Inactive</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Role" name="role">
              <Select size="large">
                <Select.Option value="SUPERADMIN">SUPERADMIN</Select.Option>
                <Select.Option value="ADMIN">ADMIN</Select.Option>
                <Select.Option value="USER">USER</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Card title="Users">
          <Table dataSource={dataSource} columns={columns} bordered />
        </Card>
      </Space>
    </Page>
  );
};
