import { useCallback } from "react";
import config from "../../../config";
import { message } from "antd";
import { useFetch } from "use-http";
import { useNavigate } from "react-router-dom";

interface WorkflowReturnType {
  loadWorkflows: (url: string) => void;
  workflows: { items: WorkflowType[]; count: number };
  createFlow: (workflow: WorkflowType) => void;
  loading: boolean;
}

export interface Edge {
  id: string;
  source: string;
  target: string;
}

export interface FormItem {
  _id?: string;
  actionType: string;
  inputType: string;
  prompt?: string;
  stepTitle: string;
}

export interface Node {
  id: string;
}

export interface WorkflowType {
  _id?: string;
  name: string;
  nodes: Node[];
  edges: Edge[];
  formItems: Record<string, FormItem>;
}

export default function useWorkflow(): WorkflowReturnType {
  const {
    get: loadWorkflows,
    data: workflows = { items: [], count: 0 },
    loading,
  } = useFetch(`${config.api}${config.apiRoutes.workflow}`);
  const navigate = useNavigate();

  const { post, response } = useFetch(
    `${config.api}${config.apiRoutes.workflow}`
  );

  const createFlow = useCallback(
    async (workflow: WorkflowType) => {
      await post(workflow);
      if (response.ok) {
        message.success("Workflow created");
        await loadWorkflows();
        navigate(config.routes.workflow);
        return;
      }
      message.error("Failed to create workflow");
    },
    [navigate, loadWorkflows, post, response.ok]
  );

  return {
    loadWorkflows,
    createFlow,
    workflows,
    loading,
  };
}
