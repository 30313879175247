import { useFetch } from "use-http";
import config from "../../../config";
import { useCallback, useEffect } from "react";
import jwt from "jwt-decode";
import { useLocalStorage } from "usehooks-ts";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

interface Login {
  email: string;
  password: string;
}

interface LoginReturnData {
  login: (loginData: Login) => Promise<void>;
  loading: boolean;
  loadingCode: boolean;
  validateCode: (code: string) => void;
}

export function useLogin(): LoginReturnData {
  const { post, response, loading } = useFetch(
    `${config.api}${config.apiRoutes.login}`
  );

  const {
    post: validate,
    response: responseCode,
    loading: loadingCode,
  } = useFetch(`${config.api}`);

  const [token, setToken] = useLocalStorage("token", { bearer: "" });
  const [user, setUser] = useLocalStorage("user", {
    role: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const navigate = useNavigate();

  const validateCode = useCallback(
    async (validationId: string) => {
      await validate(
        `${config.apiRoutes.usersValidateCode.replace(
          ":validationId",
          validationId
        )}`
      );
      if (responseCode.ok) {
        await message.success("Account validated");
        navigate(config.routes.login);
        return;
      }
      await message.error("There was an error validating the account");
    },
    [responseCode.ok, navigate, validate]
  );

  useEffect(() => {
    if (token.bearer !== "" && user.email !== "") {
      navigate(config.defaultPage);
    }
  }, [navigate, token.bearer, user.email]);

  const login = useCallback(
    async (loginData: Login) => {
      const r = await post(loginData);
      if (response.ok) {
        setToken({ bearer: r.accessToken });
        const decoded: Record<string, string> = jwt(r.accessToken);
        setUser({
          role: decoded.role,
          email: decoded.email,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
        });
        return;
      }
      if (response.status === 403) {
        navigate(config.routes.code);
        return;
      }
      message.error("There was an error logging in");
    },
    [post, response.status, response.ok, setToken, setUser, navigate]
  );

  return {
    login,
    loading,
    validateCode,
    loadingCode,
  };
}
