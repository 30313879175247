import React, { useEffect } from "react";
import { Alert, Button, Card, Form, Input, Row, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

import Page from "../../common/Page";
import useSettings from "./useSettings";
import config from "../../../config";

export const Settings: React.FC = () => {
  const { loading, save, getSettings, settings } = useSettings();
  const [form] = Form.useForm();
  const { t } = useTranslation("settings");

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    if (settings?.model != null) {
      form.setFieldValue("model", settings.model);
    }
    if (settings?.model != null) {
      form.setFieldValue("vectorDatabase", settings.vectorDatabase);
    }
    if (settings?.systemPrompt != null) {
      form.setFieldValue("systemPrompt", settings.systemPrompt);
    }
  }, [form, settings]);

  return (
    <Page>
      <Card title={t("settings")}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={16}>
            <Alert type="warning" showIcon message={t("wrongValues")} />
          </Row>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="off"
            onFinish={save}
            form={form}
          >
            <Form.Item
              label={t("systemPrompt")}
              name="systemPrompt"
              rules={[{ required: true, message: t("fieldIsRequired") }]}
            >
              <Input.TextArea size="large" rows={10} cols={10} />
            </Form.Item>
            <Form.Item
              label={t("interpretationModel")}
              name="model"
              style={{
                maxWidth: "300px",
              }}
              rules={[{ required: true, message: t("fieldIsRequired") }]}
            >
              <Select size="large">
                {config.settings.llamaOn ? (
                  <Select.Option value="llama3.2">llama3.2</Select.Option>
                ) : undefined}
                <Select.Option value="microsoft-gpt-4o">
                  Microsoft gpt-4o
                </Select.Option>
                <Select.Option value="gpt-4o">gpt-4o</Select.Option>
                <Select.Option value="gpt-4-turbo">gpt-4-turbo</Select.Option>
                <Select.Option value="gpt-4o-mini">gpt-4o-mini </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t("vectorDatabase")}
              name="vectorDatabase"
              style={{
                maxWidth: "300px",
              }}
              rules={[{ required: true, message: t("fieldIsRequired") }]}
            >
              <Select size="large">
                <Select.Option value="pinecone">Pinecone</Select.Option>
              </Select>
            </Form.Item>
            <Row>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                {t("save")}
              </Button>
            </Row>
          </Form>
        </Space>
      </Card>
    </Page>
  );
};

export default Settings;
