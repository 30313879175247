import React, { useCallback, useState } from "react";

import { useFetch } from "use-http";
import config from "../../../config";
import { Button, FormInstance, message, Popconfirm, Row, Space } from "antd";
import dayjs from "dayjs";
import { Check, Pencil, Trash, X } from "lucide-react";
import { useForm } from "antd/es/form/Form";
import { set } from "husky";

interface User {
  id: number;
  email: string;
  createdAt: string;
  role: string;
}

interface Column {
  title: string;
  dataIndex?: string;
  key: string;
}

interface UseUserReturnType {
  dataSource: User[];
  columns: Column[];
  loading: boolean;
  isEditUser: boolean;
  setIsEditUser: (is: boolean) => void;
  editForm: FormInstance;
  saveEditedUser: () => void;
  loadingUpdateUser: boolean;
  showUser: (id: string, status: string, role: string) => void;
}

export function useUser(): UseUserReturnType {
  const {
    get,
    loading,
    data: dataSource = [],
  } = useFetch(`${config.api}${config.apiRoutes.users}`, {}, []);

  const { del, loading: loadingDelete, response } = useFetch(`${config.api}`);
  const [isEditUser, setIsEditUser] = useState(false);
  const [editForm] = useForm();
  const {
    patch,
    loading: loadingUpdateUser,
    response: responseUpdateUser,
  } = useFetch(`${config.api}`);

  const showUser = useCallback(
    (id: string, status: string, role: string) => {
      console.log({
        id,
      });
      editForm.setFieldValue("id", id);
      editForm.setFieldValue("status", status);
      editForm.setFieldValue("role", role);
      setIsEditUser(true);
    },
    [editForm]
  );

  const saveEditedUser = useCallback(async () => {
    await patch(
      config.apiRoutes.userUpdate.replace(":id", editForm.getFieldValue("id")),
      {
        status: editForm.getFieldValue("status"),
        role: editForm.getFieldValue("role"),
      }
    );
    if (responseUpdateUser.ok) {
      message.success("User updated");
      get();
      setIsEditUser(false);
      return;
    }
    message.error("Failed to update user");
  }, [get, patch, responseUpdateUser.ok, editForm]);

  const deleteUser = useCallback(
    async (email: string) => {
      await del(`${config.apiRoutes.deleteUser.replace(":email", email)}`);
      if (response.ok) {
        message.success("User deleted");
        get();
        return;
      }
      message.error("Failed to delete user");
    },
    [del, get, response.ok]
  );

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        return status === "0" ? (
          <Row align="middle">
            <Check width={14} height={14} color="green" />
            Active
          </Row>
        ) : (
          <Row align="middle">
            <X width={14} height={14} color="red" />
            Inactive
          </Row>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return dayjs(createdAt).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row: {
        id: string;
        email: string;
        role: string;
        status: string;
      }) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete the user"
              description="Are you sure to delete this user?"
              onConfirm={async () => {
                await deleteUser(row.email);
              }}
              okText="Yes"
              okButtonProps={{
                size: "large",
              }}
              cancelButtonProps={{
                size: "large",
              }}
              cancelText="No"
            >
              <Button icon={<Trash height={12} width={12} />} size="large">
                Delete
              </Button>
            </Popconfirm>

            <Button
              icon={<Pencil height={12} width={12} />}
              size="large"
              onClick={() => {
                showUser(row.id, row.status, row.role);
              }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  return {
    dataSource,
    columns,
    loading: loading || loadingDelete,
    isEditUser,
    setIsEditUser,
    editForm,
    showUser,
    saveEditedUser,
    loadingUpdateUser,
  };
}

export default useUser;
