import React from "react";
import { Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { useTranslation } from "react-i18next";

import { getLanguagePreference } from "../../../utils/getLanguagePreference";
import config from "../../../config";

const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();
  const { defaultLang, available } = config.languages;

  const handleLanguageChange: SwitchChangeEventHandler = (checked) => {
    const language = checked ? defaultLang : available;
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  return (
    <Switch
      checkedChildren={defaultLang}
      unCheckedChildren={available}
      checked={getLanguagePreference(defaultLang)}
      onChange={handleLanguageChange}
    />
  );
};

export default LanguageSwitch;
