import React, { ReactElement, ReactNode, useCallback, useContext } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import config from "../../../config";

interface DecodedUser {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
}

interface UserType {
  user: DecodedUser;
  logout: () => void;
}

const defaultValue: UserType = {
  user: { email: "", role: "", firstName: "", lastName: "" },
  logout: () => {
    //
  },
};

export const UserContext = React.createContext<UserType>(defaultValue);

export const UserProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [, setToken] = useLocalStorage("token", { bearer: "" });
  const [user, setUser] = useLocalStorage("user", {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setToken({ bearer: "" });
    setUser({ email: "", role: "", firstName: "", lastName: "" });
    navigate(config.routes.login);
  }, [navigate, setToken, setUser]);

  const value = {
    user,
    logout,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = (): UserType => {
  return useContext(UserContext);
};
