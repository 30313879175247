import { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import config from "../../../config";
import { message } from "antd";
import { useFetch } from "use-http";
import { useLocalStorage } from "usehooks-ts";

const socket = io(config.ws, {
  secure: config.ws.includes("https"),
});

export interface Chat {
  _id: string;
  clientId: string;
  messages: Message[];
}

interface Message {
  role: string;
  content: string;
  clientId?: string;
}

interface Agent {
  messages: Message[];
  currentMessage: string;
  setCurrentMessage: (message: string) => void;
  sendMessage: (value: string, clientId?: string) => void;
  setMessages: (messages: Message[]) => void;
  loading: boolean;
  chats: Chat[];
  setChats: (chats: Chat[]) => void;
  loadMore: () => void;
  loadChat: (id: string) => void;
  clientId?: string;
  count: number;
  showLikeDislike: boolean;
  likeConversation: (id: string | undefined) => void;
  dislikeConversation: (id: string | undefined) => void;
  loadChats: () => void;
  setClientId: (id: string) => void;
  newChat: () => void;
}

export default function useAgent(): Agent {
  const [messages, setMessages] = useState<Message[]>([]);
  // State to store the current message
  const [currentMessage, setCurrentMessage] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [chats, setChats] = useState<Chat[]>([]);
  const [clientId, setClientId] = useState<string>();
  const [pagination, setPagination] = useState<{
    skip: number;
    limit: number;
  }>({
    skip: 0,
    limit: 5,
  });
  const [count, setCount] = useState(0);

  const { get } = useFetch(`${config.api}`);
  const [token] = useLocalStorage("token", { bearer: "" });

  const { post: like, response: likeResponse } = useFetch(`${config.api}`);
  const { post: dislike, response: dislikeResponse } = useFetch(
    `${config.api}`
  );
  const [showLikeDislike, setShowLikeDislike] = useState(true);

  // setMessages(data.messages);
  // setClientId(data.clientId);

  const loadChat = useCallback(
    async (id: string) => {
      const r = await get(`${config.apiRoutes.chatsById.replace(":id", id)}`);
      setMessages(r.messages);
      setClientId(r.clientId);
      if (r.liked || r.disliked) {
        setShowLikeDislike(false);
        return;
      }
      setShowLikeDislike(true);
    },
    [get]
  );

  const loadMore = useCallback(() => {
    setPagination((currState) => ({
      ...currState,
      skip: currState.skip + currState.limit,
    }));
  }, []);

  const loadChatsWithPagination = useCallback(
    async (pagination: { skip: number; limit: number }) => {
      const r = await get(
        `${config.apiRoutes.chats}?skip=${pagination.skip}&limit=${pagination.limit}`
      );
      if (r) {
        setCount(r?.count);
        setChats(r.items);
        // setChats((currState) => [...currState, ...r.items]);
      }
    },
    [get]
  );

  const loadChats = useCallback(() => {
    loadChatsWithPagination(pagination);
  }, [loadChatsWithPagination, pagination]);

  useEffect(() => {
    loadChats();
  }, [loadChats]);

  const newChat = useCallback(() => {
    socket.disconnect();
    setCurrentMessage("");
    setMessages([]);
    setClientId("");
    socket.connect();
  }, []);

  useEffect(() => {
    socket.connect();

    socket.on("connect", () => {
      console.log("CONNECTED");
    });
    // Listen for incoming messages
    socket.on("message", (message: Message) => {
      setLoading(false);

      setMessages((prevMessages) => {
        console.log({ prevMessages });
        if (prevMessages.length === 1) {
          loadChats();
        }
        if (message.clientId) {
          setClientId(message.clientId);
        }
        return [...prevMessages, message];
      });
    });

    socket.on("error", async (m) => {
      setLoading(false);
      await message.error(
        "There was an error connecting. Please try again later"
      );
    });

    socket.on("exception", async () => {
      setLoading(false);
      await message.error(
        "There was an error connecting. Please try again later"
      );
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.off("connect");
      socket.off("message");
      socket.disconnect();
    };
  }, [loadChats]);

  const sendMessage = (content: string, clientId?: string): void => {
    // Send the message to the server
    socket.emit("message", {
      clientId,
      token: token.bearer,
      messages: [
        {
          role: "user",
          content,
        },
      ],
    });

    setLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content, clientId },
    ]);
    setCurrentMessage("");
  };

  const likeConversation = useCallback(
    async (clientId: string | undefined) => {
      if (!clientId) {
        return;
      }
      await like(`${config.apiRoutes.like.replace(":id", clientId)}`);
      if (likeResponse.ok) {
        message.success("Conversation feedback registered");
        setShowLikeDislike(false);
      }
    },
    [like, likeResponse]
  );

  const dislikeConversation = useCallback(
    async (clientId: string | undefined) => {
      if (!clientId) {
        return;
      }
      await dislike(`${config.apiRoutes.dislike.replace(":id", clientId)}`);
      if (dislikeResponse.ok) {
        message.success("Conversation feedback registered");
        setShowLikeDislike(false);
      }
    },
    [dislike, dislikeResponse]
  );

  return {
    messages,
    currentMessage,
    setCurrentMessage,
    sendMessage,
    setMessages,
    setChats,
    loadMore,
    loading,
    chats,
    loadChat,
    clientId,
    count,
    showLikeDislike,
    likeConversation,
    dislikeConversation,
    loadChats,
    setClientId,
    newChat,
  };
}
