import { Interceptors } from "use-http";
import { useUser } from "../User/User.Context";

interface UseAppHookType {
  options: {
    interceptors: Interceptors;
  };
}

export function useHttpInterceptor(): UseAppHookType {
  const { logout } = useUser();
  const options: any = {
    persist: false,
    cachePolicy: "no-cache",
    interceptors: {
      request: async ({ options }: any) => {
        const lsToken = localStorage.getItem("token");
        let token = { bearer: "" };
        try {
          token = JSON.parse(lsToken ?? "{}");
        } catch (ex) {
          //
        }
        if (token?.bearer !== "") {
          options.headers.Authorization = `Bearer ${token.bearer}`;
        }
        return options;
      },
      response: ({ response }: any) => {
        if (response?.data?.statusCode === 401) {
          logout();
        }
        return response;
      },
    },
  };

  return {
    options,
  };
}
