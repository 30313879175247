import React, { useEffect, useMemo } from "react";
import Page from "../../common/Page";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  List,
  message,
  Row,
  Space,
  Spin,
  Tag,
  UploadProps,
} from "antd";

import Dragger from "antd/es/upload/Dragger";
import config from "../../../config";
import useTrain from "./useTrain";
import { useLocalStorage } from "usehooks-ts";
import { FileText, Inbox } from "lucide-react";

export const Files: React.FC = () => {
  const { loadDocuments, documents, deleteDoc, loading, train, trainLoad } =
    useTrain();
  const [token] = useLocalStorage("token", { bearer: "" });

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const props: UploadProps = useMemo(
    () => ({
      name: "file",
      multiple: true,
      action: config.api + "/chats/upload",
      headers: {
        Authorization: `Bearer ${token.bearer}`,
      },
      onDrop(e: unknown) {
        console.log("Dropped files", e);
      },
      onChange(info) {
        if (info.file.status === "error") {
          message.error(info?.file?.response?.message);
        }
        if (info.file.status === "done") {
          message.success("File uploaded");
          loadDocuments();
        }
      },
    }),
    [token, loadDocuments]
  );

  return (
    <Page>
      <Card className="card" bordered title="Files">
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Row className="full-width">
            <Col xs={24}>
              <Alert
                showIcon
                type="warning"
                description="The training only works for PDF documents, other formats are excluded"
              />
              <Spin spinning={loading} className="full-width">
                <List
                  className="full-width"
                  itemLayout="horizontal"
                  dataSource={documents}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: "#4096ff",
                            }}
                          >
                            <FileText width={12} height={12} />
                          </Avatar>
                        }
                        title={item.name}
                        description={item.mimetype}
                      />
                      <Tag color={!item.trained ? "processing" : "success"}>
                        {item.trained ? "Trained" : "Not used for training"}
                      </Tag>
                      <Button
                        danger
                        onClick={() => {
                          deleteDoc(item.name);
                        }}
                      >
                        Delete
                      </Button>
                    </List.Item>
                  )}
                />
              </Spin>
            </Col>
          </Row>
          <Row>
            <Button
              loading={trainLoad}
              disabled={trainLoad || documents.length === 0}
              type="primary"
              size="large"
              shape="round"
              onClick={train}
            >
              Train
            </Button>
          </Row>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <Inbox />
            </p>
            <p className="ant-upload-text">Upload files</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from
              uploading company data or other banned files.
            </p>
          </Dragger>
        </Space>
      </Card>
    </Page>
  );
};
