import { useFetch } from "use-http";
import { Form, message } from "antd";
import { useCallback } from "react";

import config from "../../../config";
import { useUser } from "../../common/User/User.Context";

export default function useAccount() {
  const { data: defaultMe } = useFetch(
    `${config.api}${config.apiRoutes.me}`,
    {},
    []
  );

  const { logout } = useUser();

  const {
    patch,
    response: responseUpdate,
    loading: updateLoading,
  } = useFetch(`${config.api}${config.apiRoutes.userUpdateMe}`);

  const {
    del,
    response: deleteResponse,
    loading: deleteLoading,
  } = useFetch(`${config.api}${config.apiRoutes.userUpdateMe}`);

  const {
    patch: updatePass,
    response: responsePass,
    loading: updatePassLoading,
  } = useFetch(`${config.api}${config.apiRoutes.userUpdateMePassword}`);

  const {} = useFetch(`${config.api}${config.apiRoutes.userUpdate}`);
  const [updateInfoForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const deleteMe = useCallback(async () => {
    await del();
    if (deleteResponse.ok) {
      message.success("User deleted");
      logout();
      return;
    }
    message.error("User delete failed");
  }, [deleteResponse, logout, del]);

  const updatePassword = useCallback(
    async ({ password }: { password: string }) => {
      await updatePass({ password });
      if (responsePass.ok) {
        message.success("User password updated");
        logout();
        return;
      }
      message.error("User password update failed");
    },
    [updatePass, responsePass, logout]
  );

  const updateNames = useCallback(
    async ({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) => {
      await patch({ firstName, lastName });
      if (responseUpdate.ok) {
        message.success("User information updated");
        return;
      }
      message.error("User information update failed");
    },
    [patch, responseUpdate]
  );

  return {
    defaultMe,
    updateInfoForm,
    passwordForm,
    updateNames,
    updateLoading,
    updatePassLoading,
    updatePassword,
    deleteMe,
    deleteLoading,
  };
}
