import React from "react";
import { useForm } from "antd/es/form/Form";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./register.module.css";
import config from "../../../config";
import { useRegister } from "./useRegister";

export const Register: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { register, loading } = useRegister();
  const { t: tRegister } = useTranslation("register");
  return (
    <Layout className={styles.bg}>
      <Space
        direction="vertical"
        className="full-width center-page"
        size="large"
      >
        <Row justify="center">
          <img alt="logo" src={config.logo} height={90} />
        </Row>
        <Row justify="center" align="middle">
          <Form
            disabled={loading}
            layout="vertical"
            form={form}
            name="register"
            scrollToFirstError
            className={styles.formWidth}
            onFinish={register}
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: tRegister("validation.emailFormat"),
                },
                {
                  required: true,
                  message: tRegister("validation.email"),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={tRegister("firstName")}
              rules={[
                {
                  required: true,
                  message: tRegister("validation.firstName"),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={tRegister("lastName")}
              rules={[
                {
                  required: true,
                  message: tRegister("validation.lastName"),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label={tRegister("password")}
              rules={[
                {
                  required: true,
                  message: tRegister("validation.password"),
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="repeatPassword"
              label={tRegister("repeatPassword")}
              rules={[
                {
                  required: true,
                  message: tRegister("validation.passwordNoMatch"),
                },
                ({ getFieldValue }) => ({
                  async validator(_, value) {
                    if (
                      value === undefined ||
                      value === "" ||
                      getFieldValue("password") === value
                    ) {
                      return await Promise.resolve();
                    }
                    throw new Error(tRegister("validation.passwordNoMatch"));
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              name="terms"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Checkbox>
                {tRegister("register:readTerms")}
                <Link to={config.routes.terms} target="_blank">
                  {tRegister("register:termsAndConditions")}
                </Link>
                &nbsp;{tRegister("register:and")}&nbsp;
                <Link to={config.routes.privacy} target="_blank">
                  {tRegister("register:privacyPolicy")}
                </Link>
              </Checkbox>
            </Form.Item>
            <Row>
              <Typography.Paragraph>
                {tRegister("register:alreadyHaveAccount")}
                <Button
                  type="link"
                  onClick={() => {
                    navigate(config.routes.login);
                  }}
                >
                  {tRegister("register:login")}
                </Button>
              </Typography.Paragraph>
            </Row>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {tRegister("register:register")}
            </Button>
          </Form>
        </Row>
      </Space>
    </Layout>
  );
};
