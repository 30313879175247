import { useCallback } from "react";
import config from "../../../config";
import { useFetch } from "use-http";
import { message } from "antd";

interface Document {
  name: string;
  mimetype: string;
  trained: boolean;
  _id: string;
}

interface Train {
  documents: Document[];
  loadDocuments: () => void;
  deleteDoc: (name: string) => void;
  loading: boolean;
  train: () => void;
  trainLoad: boolean;
}

export default function useTrain(): Train {
  const {
    get: loadDocuments,
    data: documents = [],
    loading: loadingDocuments,
  } = useFetch(`${config.api}${config.apiRoutes.documents}`);

  const { del, loading: loadingDeleteDoc } = useFetch(
    `${config.api}${config.apiRoutes.documents}`
  );

  const { post: trainPost, loading: trainLoad } = useFetch(
    `${config.api}${config.apiRoutes.chatsTrain}`
  );

  const deleteDoc = useCallback(
    async (name: string) => {
      try {
        await del(`/${name}`);
        await loadDocuments();
        message.success("Document deleted");
      } catch (ex) {
        message.error("Error deleting document");
      }
    },
    [del, loadDocuments]
  );

  const train = useCallback(async () => {
    try {
      await trainPost();
      await loadDocuments();
      message.success("AI trained");
    } catch (ex) {
      message.error("Error training AI");
    }
  }, [loadDocuments, trainPost]);

  return {
    documents,
    loadDocuments,
    deleteDoc,
    loading: loadingDeleteDoc || loadingDocuments,
    train,
    trainLoad,
  };
}
