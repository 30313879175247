import React, { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Space,
  Tabs,
  TabsProps,
} from "antd";

import Page from "../../common/Page";
import styles from "./account.module.css";
import useAccount from "./useAccount";
import { CircleX } from "lucide-react";
import { useTranslation } from "react-i18next";

export const Account: React.FC = () => {
  const {
    defaultMe,
    updateNames,
    updateInfoForm,
    passwordForm,
    updateLoading,
    updatePassLoading,
    updatePassword,
    deleteLoading,
    deleteMe,
  } = useAccount();

  const { t } = useTranslation("account");

  useEffect(() => {
    if (defaultMe) {
      updateInfoForm.setFieldValue("firstName", defaultMe.firstName);
      updateInfoForm.setFieldValue("lastName", defaultMe.lastName);
    }
  }, [defaultMe, updateInfoForm]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("userInformation"),
      children: (
        <Form
          initialValues={defaultMe}
          layout="vertical"
          name="update-names"
          scrollToFirstError
          form={updateInfoForm}
          className={styles.form}
          onFinish={updateNames}
        >
          <Form.Item
            name="firstName"
            label={t("firstName")}
            rules={[
              {
                required: true,
                message: t("validation.firstName"),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t("lastName")}
            rules={[
              {
                required: true,
                message: t("validation.lastName"),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Button
            size="large"
            disabled={updateLoading}
            type="primary"
            htmlType="submit"
            loading={updateLoading}
          >
            {t("saveInformation")}
          </Button>
        </Form>
      ),
    },
    {
      key: "2",
      label: t("password"),
      children: (
        <Form
          onFinish={updatePassword}
          layout="vertical"
          name="update-password"
          scrollToFirstError
          form={passwordForm}
          className={styles.form}
        >
          <Form.Item
            name="password"
            label={t("password")}
            rules={[
              {
                required: true,
                message: t("validation.password"),
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            name="repeatPassword"
            label={t("repeatPassword")}
            rules={[
              {
                required: true,
                message: t("validation.passwordNoMatch"),
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (
                    value === undefined ||
                    value === "" ||
                    getFieldValue("password") === value
                  ) {
                    return await Promise.resolve();
                  }
                  throw new Error(t("validation.passwordNoMatch"));
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={updatePassLoading}
          >
            {t("saveInformation")}
          </Button>
        </Form>
      ),
    },
  ];

  return (
    <Page>
      <Space direction="vertical" className="full-width">
        <Card title={t("updateUserInformation")}>
          <Tabs defaultActiveKey="1" items={items} />
        </Card>
        <Card title={t("actions")}>
          <Popconfirm
            title={t("deleteUserAccount")}
            description={t("areYouSureDelete")}
            onConfirm={async () => {
              //
            }}
            okText="Yes"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
            cancelText="No"
          >
            <Button
              size="large"
              type="dashed"
              loading={deleteLoading}
              onClick={deleteMe}
              icon={<CircleX width={12} height={12} />}
            >
              {t("deleteAccount")}
            </Button>
          </Popconfirm>
        </Card>
      </Space>
    </Page>
  );
};
