import React from "react";
import { Button, Card, Descriptions, Space, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { Highlight, themes } from "prism-react-renderer";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../common/Page";
import { useFetch } from "use-http";
import config from "../../../config";
import { DataType, StatusStep, StepType } from "./types";
import dayjs from "dayjs";
import { ArrowLeft, Sun, AlertCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

const initialData: DataType = {
  completed: false,
  failed: false,
  createdAt: "",
  _id: "",
  workflowId: "",
  steps: [],
};

export const WorkflowResult: React.FC = () => {
  const navigate = useNavigate();
  const param = useParams() as { id: string };

  const { data = initialData satisfies DataType } = useFetch(
    `${config.api}${config.apiRoutes.workflowRunById.replace(":id", param.id)}`,
    {},
    []
  );

  const { t } = useTranslation("workflow");

  return (
    <Page>
      <Card
        bordered
        title={
          <Space>
            <Button
              type="link"
              icon={<ArrowLeft width={12} height={12} />}
              className="no-pad"
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>
            <Typography.Paragraph className="no-margin">
              {t("workflowResult")}
            </Typography.Paragraph>
          </Space>
        }
      >
        <Space direction="vertical">
          <Card title={t("taskInformation")}>
            <Descriptions>
              <Descriptions.Item label="Id">{data._id}</Descriptions.Item>
              <Descriptions.Item label={t("timestamp")}>
                {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
              </Descriptions.Item>
              <Descriptions.Item label={t("status")}>
                <Space>
                  {data.failed ? (
                    <AlertCircle width={12} height={12} color="red" />
                  ) : (
                    <Sun width={12} height={12} color="green" />
                  )}
                  {data.failed ? t("failed") : t("passed")}
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title={t("result")}>
            {data.steps.map((step: StepType) => {
              return (
                <Paragraph key={step.stepTitle}>
                  <Descriptions>
                    <Descriptions.Item label={t("title")}>
                      {step.stepTitle}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("actionType")}>
                      {step.actionType}
                    </Descriptions.Item>
                    {step.prompt ? (
                      <Descriptions.Item label={t("prompt")}>
                        {step.prompt}
                      </Descriptions.Item>
                    ) : (
                      ""
                    )}
                    <Descriptions.Item label={t("status")}>
                      <Space>
                        {step.status === StatusStep.FAILED ? (
                          <AlertCircle width={12} height={12} color="red" />
                        ) : (
                          <Sun width={12} height={12} color="green" />
                        )}
                        {step.status === StatusStep.FAILED
                          ? t("failed")
                          : t("passed")}
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("timestamp")}>
                      {dayjs(step.timestamp).format("YYYY-MM-DD HH:mm")}
                    </Descriptions.Item>

                    <Descriptions.Item label={t("inputData")}>
                      {step.inputType}
                    </Descriptions.Item>
                  </Descriptions>
                  <Highlight
                    theme={themes.oneDark}
                    language="markdown"
                    code={step.data || ""}
                  >
                    {({
                      className,
                      style,
                      tokens,
                      getLineProps,
                      getTokenProps,
                    }) => (
                      <pre style={style}>
                        {tokens.map((line, i) => (
                          <div key={i} {...getLineProps({ line })}>
                            {line.map((token, key) => (
                              <span key={key} {...getTokenProps({ token })} />
                            ))}
                          </div>
                        ))}
                      </pre>
                    )}
                  </Highlight>
                </Paragraph>
              );
            })}
          </Card>
        </Space>
      </Card>
    </Page>
  );
};
