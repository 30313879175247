import React, { useCallback, useEffect, useState } from "react";
import {
  addEdge,
  Background,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import {
  FloatButton,
  Modal,
  Space,
  Form,
  Input,
  Select,
  Row,
  Spin,
  Typography,
  Button,
  Card,
} from "antd";
import { Connection } from "@xyflow/system/dist/esm/types/general";
import "@xyflow/react/dist/style.css";
import Page from "../../common/Page";
import {
  BorderHorizontalOutlined,
  BorderRightOutlined,
  LeftOutlined,
  MoreOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { highlight, languages } from "prismjs";

import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

import styles from "./workflow.module.css";
import useWorkflow, { Edge } from "./useWorkflow";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "use-http";
import config from "../../../config";
import Editor from "react-simple-code-editor";
import cs from "classnames";
import { useTranslation } from "react-i18next";

interface Node {
  id: string;
  type?: string;
  dragging?: boolean;
  selected?: boolean;
  measured?: {
    width: number;
    height: number;
  };
  data: { label: string | React.ReactNode };
  position: { x: number; y: number };
}

const initialNodes: Node[] = [
  {
    id: "1",
    type: "input",
    dragging: false,
    selected: false,
    measured: {
      width: 150,
      height: 36,
    },
    data: { label: "Start" },
    position: { x: 4, y: 100 },
  },
];

interface FormStepItem {
  stepTitle: string;
  prompt?: string;
  inputType: string;
  actionType: string;
  functionSignature?: string;
  functionCode?: string;
}

const initialFormItems: Record<string, FormStepItem> = {};

export const WorkflowNew: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  const [nameForm] = useForm();
  const [selection, setSelection] = useState(undefined as Node | undefined);
  const [formItems, setFormItems] = useState(initialFormItems);
  const { createFlow, loading } = useWorkflow();
  const [workflowName, setWorkflowName] = useState("");
  const [id, setId] = useState();
  const [functionSignature, setFunctionSignature] = React.useState(
    "{\n" +
      '    "name": "get_delivery_date",\n' +
      '    "description": "Get the delivery date for a customer\'s order. Call this whenever you need to know the delivery date, for example when a customer asks \'Where is my package\'",\n' +
      '    "parameters": {\n' +
      '        "type": "object",\n' +
      '        "properties": {\n' +
      '            "order_id": {\n' +
      '                "type": "string",\n' +
      '                "description": "The customer\'s order ID."\n' +
      "            }\n" +
      "        },\n" +
      '        "required": ["order_id"],\n' +
      '        "additionalProperties": false\n' +
      "    }\n" +
      "}"
  );
  const [functionCode, setFunctionCode] = useState(
    "function get_delivery_date(data) {\n" + "    return data.date;\n" + "}"
  );

  const [validFunctionCall, setValidFunctionCall] = useState(true);
  const [isFunctionCall, setIsFunctionCall] = useState(false);
  const navigate = useNavigate();

  const param = useParams() as { id?: string };
  const {
    get,
    response,
    data = { edges: [], nodes: [] },
  } = useFetch(`${config.api}`);

  const getWorkflowById = useCallback(async () => {
    if (param.id !== undefined) {
      await get(`${config.apiRoutes.workflowById.replace(":id", param.id)}`);
    }
  }, [get, param.id]);

  const { t } = useTranslation("workflow");

  useEffect(() => {
    getWorkflowById();
  }, [getWorkflowById, param.id]);

  useEffect(() => {
    if (response.ok) {
      if (data.nodes.length && data.edges.length) {
        setNodes(
          data.nodes.map((n: Node, index: number) => ({
            ...n,
            position: { x: n.position.x + index * 150, y: 10 },
          }))
        );
        setEdges(data.edges);
        setFormItems(data.formItems);
        setWorkflowName(data.name);
        setId(data._id);
        nameForm.setFieldValue("name", data.name);
      }
    }
  }, [nameForm, data, data.edges, data.nodes, response.ok, setEdges, setNodes]);

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const addNode = useCallback(() => {
    const newNode: Node = {
      id: (nodes.length + 1).toString(),
      data: { label: "Step" },
      dragging: false,
      selected: false,
      measured: {
        width: 150,
        height: 36,
      },
      position: { x: 0, y: 100 },
    };
    setNodes([...nodes, newNode]);
  }, [nodes, setNodes]);

  const addEnd = useCallback(() => {
    const newNode: Node = {
      id: (nodes.length + 1).toString(),
      data: { label: "End" },
      type: "output",
      dragging: false,
      selected: false,
      measured: {
        width: 150,
        height: 36,
      },
      position: { x: 0, y: 100 },
    };
    setNodes([...nodes, newNode]);
  }, [nodes, setNodes]);

  // Update nodes label
  useEffect(() => {
    for (const item in formItems) {
      setNodes((prevNodes) => {
        return prevNodes.map((node) => {
          if (node.id === item) {
            return {
              ...node,
              data: {
                label: formItems[item].stepTitle,
              },
            };
          }
          return node;
        });
      });
    }
  }, [formItems, setNodes]);

  const onFinish = useCallback(() => {
    if (!validFunctionCall) {
      return;
    }
    if (selection !== undefined) {
      setFormItems((prevState) => {
        return {
          ...prevState,
          [selection?.id]: {
            stepTitle: form.getFieldValue("stepTitle"),
            prompt: form.getFieldValue("prompt"),
            inputType: form.getFieldValue("inputType"),
            actionType: form.getFieldValue("actionType"),
            functionSignature:
              form.getFieldValue("actionType") === "functionCall"
                ? JSON.stringify(JSON.parse(functionSignature))
                : undefined,
            functionCode:
              form.getFieldValue("actionType") === "functionCall"
                ? functionCode
                : undefined,
          },
        };
      });
    }
  }, [form, selection, functionSignature, functionCode, validFunctionCall]);

  return (
    <Page>
      <Space>
        <Modal
          title={t("defineStep")}
          width={840}
          style={{
            overflow: "scroll",
          }}
          open={isModalOpen}
          onOk={async () => {
            onFinish();
            setIsModalOpen(false);
          }}
          onCancel={() => {
            //
            setIsModalOpen(false);
          }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            onFinishFailed={() => {
              //
            }}
          >
            <Form.Item
              label={t("stepTitle")}
              name="stepTitle"
              rules={[{ required: true, message: t("validation.stepTitle") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t("prompt")} name="prompt">
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              label={t("inputType")}
              name="inputType"
              rules={[{ required: true, message: t("validation.inputTitle") }]}
            >
              <Select
                defaultValue=""
                options={[
                  { value: "", label: t("none") },
                  { value: "previousStep", label: t("previousStep") },
                  { value: "json", label: t("json") },
                  { value: "text", label: t("text") },
                  { value: "file", label: t("file") },
                ].filter((item) => {
                  if (selection?.type === "input") {
                    return item.value !== "previousStep";
                  }
                  return true;
                })}
              />
            </Form.Item>
            <Form.Item
              label={t("actionType")}
              name="actionType"
              rules={[{ required: true, message: t("validation.stepTitle") }]}
            >
              <Select
                defaultValue=""
                onChange={(e) => {
                  if (e === "functionCall") {
                    setIsFunctionCall(true);
                    return;
                  }
                  setIsFunctionCall(false);
                }}
                options={[
                  { value: "", label: t("none") },
                  { value: "exportToPdf", label: t("exportToPdf") },
                  { value: "functionCall", label: t("functionCall") },
                  { value: "console", label: t("exportToConsole") },
                  { value: "passToNextStep", label: t("passToNextStep") },
                ].filter((item) => {
                  if (selection?.type === "output") {
                    return item.value !== "passToNextStep";
                  }
                  return true;
                })}
              />
            </Form.Item>
            {isFunctionCall && (
              <Space direction="vertical">
                <div>
                  <Typography.Text>{t("functionDefinition")}</Typography.Text>
                  <Editor
                    className={cs([
                      styles.editorBorder,
                      !validFunctionCall ? styles.editorBorderError : "",
                    ])}
                    name={"functionSignature"}
                    value={functionSignature}
                    onValueChange={(codeSig: string) => {
                      setFunctionSignature(codeSig);
                      try {
                        JSON.parse(codeSig);
                        setValidFunctionCall(true);
                      } catch (ex) {
                        setValidFunctionCall(false);
                      }
                    }}
                    highlight={(codeSig: string) => {
                      return highlight(
                        codeSig,
                        languages.javascript,
                        "javascript"
                      );
                    }}
                    padding={10}
                  />
                  {!validFunctionCall ? (
                    <Typography.Text type="danger">
                      {t("notValidJson")}
                    </Typography.Text>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <Typography.Text>{t("nodeFunction")}</Typography.Text>
                  <Editor
                    className={styles.editorBorder}
                    name={"functionCode"}
                    value={functionCode}
                    onValueChange={(funcCode: string) =>
                      setFunctionCode(funcCode)
                    }
                    highlight={(funcCode: string) => {
                      return highlight(funcCode, languages.javascript, "json");
                    }}
                    padding={10}
                  />
                </div>
              </Space>
            )}
          </Form>
        </Modal>
        <FloatButton.Group
          shape="square"
          trigger="click"
          style={{ insetInlineEnd: 88 }}
          icon={<MoreOutlined />}
        >
          <FloatButton
            tooltip={t("addNode")}
            icon={<BorderHorizontalOutlined />}
            onClick={addNode}
          />
          <FloatButton
            tooltip={t("addEnd")}
            icon={<BorderRightOutlined />}
            onClick={addEnd}
          />
          <FloatButton
            tooltip={t("save")}
            type="primary"
            icon={<SaveOutlined />}
            onClick={() =>
              createFlow({
                name: workflowName,
                nodes,
                edges,
                formItems,
                _id: id,
              })
            }
          />
        </FloatButton.Group>
      </Space>
      <Card>
        <Space direction="vertical">
          <Row>
            <Button
              type="link"
              icon={<LeftOutlined />}
              className="no-pad"
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>
          </Row>
          <Row className="full-width">
            <Form layout="vertical" form={nameForm}>
              <Form.Item
                required
                name="name"
                label={t("workflowName")}
                rules={[
                  { required: true, message: t("validation.fieldRequired") },
                ]}
              >
                <Input
                  value={workflowName}
                  size="large"
                  onChange={(e) => {
                    setWorkflowName(e.target.value);
                  }}
                />
              </Form.Item>
            </Form>
          </Row>
        </Space>

        <Spin spinning={loading}>
          <div className={styles.reactFlow}>
            <ReactFlow
              nodes={nodes}
              minZoom={1}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDoubleClick={(e) => {
                setIsFunctionCall(false);
                const dataId: string | null = (
                  e.target as HTMLElement
                ).getAttribute("data-id");
                form.setFieldsValue({
                  stepTitle: nodes.find((node) => node.id === dataId)?.data
                    .label,
                  prompt: "",
                  inputType: "none",
                  actionType: "",
                });
                const nodeId: string | undefined = nodes.find(
                  (node) => node.id === dataId
                )?.id;
                setSelection(nodes.find((node) => node.id === dataId));
                if (nodeId !== undefined && nodeId in formItems) {
                  form.setFieldsValue(formItems[nodeId]);
                  if (formItems[nodeId].actionType === "functionCall") {
                    setIsFunctionCall(true);
                    setFunctionSignature(
                      JSON.stringify(
                        JSON.parse(
                          formItems[nodeId]?.functionSignature || "{}"
                        ),
                        null,
                        2
                      ) || "{}"
                    );
                    setFunctionCode(formItems[nodeId]?.functionCode || "");
                  }
                }
                setIsModalOpen(true);
              }}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        </Spin>
      </Card>
    </Page>
  );
};
