import React from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { Result } from "antd";
import { Provider } from "use-http";

import Dashboard from "./Dashboard/Dashboard";
import config from "../../config";
import { Files } from "./Files/Files";
import { Agent } from "./Agent/Agent";
import { Workflow } from "./Workflow/Workflow";
import { WorkflowNew } from "./Workflow/WorkflowNew";
import Settings from "./Settings/Settings";
import { WorkflowHistory } from "./Workflow/WorkflowHistory";
import { WorkflowResult } from "./Workflow/WorkflowResult";
import { Register } from "./Register/Register";
import { Login } from "./Login/Login";
import { Users } from "./Users/Users";
import { useHttpInterceptor } from "../common/Http/useHttpInterceptor";
import PrivateRoute from "../common/PrivateRoute/PrivateRoute";
import { ApiKey } from "./ApiKey/ApiKey";
import { Code } from "./Code/Code";
import { Account } from "./Account/Account";
import { Terms } from "./Terms/Terms";
import { Privacy } from "./Privacy/Privacy";

export const Pages: React.FC = () => {
  const { options } = useHttpInterceptor();
  return (
    <Provider options={options}>
      <Routes>
        {config.settings.dashboardOn && (
          <Route
            path={config.routes.dashboard}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        )}
        <Route path={config.routes.code} element={<Code />} />
        <Route
          path={config.routes.agent}
          element={
            <PrivateRoute>
              <Agent />
            </PrivateRoute>
          }
        />
        <Route path={config.routes.terms} element={<Terms />} />
        <Route path={config.routes.privacy} element={<Privacy />} />
        <Route
          path={config.routes.files}
          element={
            <PrivateRoute>
              <Files />
            </PrivateRoute>
          }
        />
        <Route
          path={config.routes.settings}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        {config.settings.workflowOn && (
          <Route
            path={config.routes.workflowEdit}
            element={
              <PrivateRoute>
                <WorkflowNew />
              </PrivateRoute>
            }
          />
        )}
        {config.settings.workflowOn && (
          <Route
            path={config.routes.workflow}
            element={
              <PrivateRoute>
                <Workflow />
              </PrivateRoute>
            }
          />
        )}
        {config.settings.workflowOn && (
          <Route
            path={config.routes.workflowNew}
            element={
              <PrivateRoute>
                <WorkflowNew />
              </PrivateRoute>
            }
          />
        )}
        {config.settings.workflowOn && (
          <Route
            path={config.routes.workflowResult}
            element={
              <PrivateRoute>
                <WorkflowResult />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path={config.routes.account}
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route path={config.routes.register} element={<Register />} />
        <Route path={config.routes.login} element={<Login />} />
        <Route
          path={config.routes.user}
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        {config.settings.workflowOn && (
          <Route
            path={config.routes.workflowHistory}
            element={<WorkflowHistory />}
          />
        )}
        {config.settings.apiKeysOn && (
          <Route path={config.routes.apiKeys} element={<ApiKey />} />
        )}

        <Route
          path="/"
          element={<Navigate to={config.routes.login} replace />}
        />
        <Route
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Link to="/">Back Home</Link>}
            />
          }
        />
      </Routes>
    </Provider>
  );
};

export default Pages;
