import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  List,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import cs from "classnames";
import Markdown from "react-markdown";

import { Spinner } from "../../common/Spinner/Spinner";
import Page from "../../common/Page";

import useAgent from "./useAgent";

import {
  ArrowUp,
  File,
  HelpCircle,
  MessageCircle,
  Plus,
  CircleUserRound,
  ThumbsUp,
  ThumbsDown,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import LogoContainer from "../../common/LogoContainer/LogoContainer";

import style from "./Agent.module.css";

export const Agent: React.FC = () => {
  const {
    messages,
    sendMessage,
    loading,
    setCurrentMessage,
    currentMessage,
    chats,
    loadChat,
    clientId,
    likeConversation,
    dislikeConversation,
    showLikeDislike,
    newChat,
  } = useAgent();

  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    chatRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const { t } = useTranslation("agent");

  return (
    <Page>
      <Row align="top" justify="center" className="full-width full-height">
        <Card className={cs([style.card, style.contentCenter])}>
          <Row className={cs(["full-height"])}>
            <Col lg={20} xs={24} className={style.chat} ref={chatRef}>
              {messages.length > 0 ? (
                messages.map((item) => {
                  if (item.role === "assistant") {
                    return (
                      <div key={item.content}>
                        <Row
                          align="top"
                          wrap={false}
                          gutter={10}
                          className={style.gridAssistant}
                        >
                          <Col>
                            {item.role !== "assistant" ? (
                              <CircleUserRound width={24} />
                            ) : (
                              <LogoContainer
                                size="small"
                                logoSrc={config.logo}
                              />
                            )}
                          </Col>
                          <Col>
                            {" "}
                            <p className={cs([style.marginLeft, style.pLeft])}>
                              <Markdown>{item.content}</Markdown>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                  return (
                    <div key={item.content}>
                      <div className={style.gridUser}>
                        <Markdown
                          className={cs([
                            style.marginLeft,
                            style.pRight,
                            style.borderMarkdown,
                          ])}
                        >
                          {item.content}
                        </Markdown>
                        {item.role !== "assistant" ? (
                          <CircleUserRound width={24} />
                        ) : (
                          <img alt="logo" src={config.logo} width={24} />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={style.spaceBtw}>
                  <Row justify="center" align="middle">
                    <LogoContainer size="large" logoSrc={config.logo} />
                  </Row>
                  <Row justify="center" align="top" gutter={20}>
                    <Col lg={10}>
                      <Typography.Paragraph
                        style={{
                          textAlign: "center",
                          marginTop: 16,
                        }}
                      >
                        {t("description")}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                  <Row justify="center" align="top" gutter={20}>
                    <Col>
                      <div className={style.cardGpt} onClick={() => {
                        sendMessage(t("card.first"), clientId);
                      }}>
                        <MessageCircle width={48} />
                        <p className={style.cardText}>{t("card.first")}</p>
                      </div>
                    </Col>
                    <Col>
                      <div className={style.cardGpt} onClick={() => {
                        sendMessage(t("card.second"), clientId);
                      }}>
                        <MessageCircle width={48} />
                        <p className={style.cardText}>{t("card.second")}</p>
                      </div>
                    </Col>
                    <Col>
                      <div className={style.cardGpt} onClick={() => {
                        sendMessage(t("card.third"), clientId);
                      }}>
                        <MessageCircle width={48} />
                        <p className={style.cardText}>{t("card.third")}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
            <Row>{loading ? <Skeleton active /> : null}</Row>
            <Row className={style.searchBar}>
              <Input
                disabled={loading}
                value={currentMessage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentMessage(e.target.value);
                }}
                onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  sendMessage((e.target as HTMLInputElement).value, clientId);
                }}
                size="large"
                className={cs([style.content, style.searchBarInput])}
                placeholder={t("startTyping")}
                suffix={
                  loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      onClick={() => {
                        sendMessage(currentMessage, clientId);
                      }}
                      type="default"
                      icon={<ArrowUp />}
                    />
                  )
                }
              />
              {showLikeDislike ? (
                <>
                  <Tooltip title={t("likeConversation")}>
                    <Button
                      disabled={messages.length === 0}
                      type="link"
                      onClick={() => likeConversation(clientId)}
                      icon={<ThumbsUp />}
                    />
                  </Tooltip>

                  <Tooltip title={t("dislikeConversation")}>
                    <Button
                      disabled={messages.length === 0}
                      type="link"
                      onClick={() => dislikeConversation(clientId)}
                      icon={<ThumbsDown />}
                    />
                  </Tooltip>
                </>
              ) : null}
            </Row>
            <Col className={style.chatHistory} lg={4} xs={0}>
              <Button
                size="large"
                type="primary"
                shape="round"
                style={{
                  margin: "0 20px",
                }}
                onClick={newChat}
              >
                {t("newChat")}
              </Button>
              <List
                header={
                  <Typography.Text strong>{t("chatHistory")}</Typography.Text>
                }
                className={cs([style.list, "full-height"])}
                bordered
                dataSource={[
                  ...chats.map((chat) => (
                    <button
                      onClick={() => loadChat(chat.clientId)}
                      key={chat.clientId}
                      className={style.chatBtn}
                    >
                      {chat.messages.length > 1 ? (
                        <>
                          <strong>
                            {chat.messages[0]?.content?.substring(0, 14)}
                          </strong>
                          <p>
                            {chat.messages[1]?.content?.substring(0, 20) +
                              "..."}
                          </p>
                          {chat.messages[0]?.content.length > 20 ? "..." : ""}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </button>
                  )),
                  // <Button
                  //   disabled={chats.length >= count}
                  //   key="load more"
                  //   type="link"
                  //   onClick={loadMore}
                  // >
                  //   {chats.length >= count ? "No more chats" : "Load more..."}
                  // </Button>,
                ]}
                renderItem={(item) => <>{item}</>}
              />
            </Col>
          </Row>
        </Card>
      </Row>
    </Page>
  );
};
