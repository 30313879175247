import { useFetch } from "use-http";
import config from "../../../config";
import { useEffect, useState } from "react";

export default function useApiKey() {
  const {
    data: apiKeys = [],
    loading,
    get,
  } = useFetch(`${config.api}${config.apiRoutes.apiKeys}`, {}, []);

  const {
    post,
    response,
    data,
    loading: loadingPost,
  } = useFetch(`${config.api}${config.apiRoutes.apiKeys}`);

  const { del } = useFetch(`${config.api}`);

  const [lastKey, setLastKey] = useState("");

  const deleteApiKey = async (key: string) => {
    await del(`${config.apiRoutes.apiKeyDelete.replace(":id", key)}`);
    await get();
  };

  useEffect(() => {
    if (response.ok) {
      setLastKey(data?.key);
    }
  }, [data?.key, response.ok]);

  return {
    apiKeys,
    loading: loading || loadingPost,
    post,
    lastKey,
    get,
    deleteApiKey,
  };
}
