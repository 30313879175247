import React from "react";
import Page from "../../common/Page";
import { Button, Card, Col, Row, Space, Statistic, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { useFetch } from "use-http";

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { data = { workflows: 0, chats: 0, workflowsRuns: 0 } } = useFetch(
    `${config.api}${config.apiRoutes.workflowStats}`,
    {},
    []
  );

  return (
    <Page>
      <Space direction="vertical" className="full-width">
        <Row gutter={16}>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic title="Workflows" value={data.workflows} />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic title="Workflows Runs" value={data.workflowsRuns} />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic title="Chats" value={data.chats} />
            </Card>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => navigate(config.routes.agent)}
          size="large"
        >
          Go to agent
        </Button>
      </Space>
    </Page>
  );
};

export default Dashboard;
