import React, { useEffect, useState } from "react";
import { Button, Card, Space, Table, Tag, Typography } from "antd";
import { useFetch } from "use-http";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../common/Page";
import config from "../../../config";
import { DataType } from "./types";
import dayjs from "dayjs";
import { Eye, ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";

export const WorkflowHistory: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("workflow");

  const param = useParams() as { id: string };

  const { get, data: dataSource = { items: [], count: 0 } } = useFetch(
    `${config.api}${config.apiRoutes.workflowHistory.replace(":id", param.id)}`
  );

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  useEffect(() => {
    get(
      `?skip=${(pagination.page - 1) * pagination.pageSize}&limit=${
        pagination.pageSize
      }`
    );
  }, [pagination, get]);

  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: t("status"),
      key: "status",
      render: (row: DataType) => {
        if (row.failed) {
          return <Tag color="red">{t("fail")}</Tag>;
        }
        if (row.completed) {
          return <Tag color="green">{t("passed")}</Tag>;
        }
        return <Tag color="blue">{t("inProgress")}</Tag>;
      },
    },
    {
      title: t("stepRun"),
      key: "step",
      render: (row: DataType) => {
        return <p>{row.steps.length}</p>;
      },
    },
    {
      title: t("timestamp"),
      key: "timestamp",
      render: (row: DataType) => {
        return <p>{dayjs(row.createdAt).format("YYYY-MM-DD HH:mm")}</p>;
      },
    },
    {
      title: t("action"),
      key: "action",
      render: (row: DataType) => (
        <Space>
          <Button
            type="dashed"
            icon={<Eye height={12} width={12} />}
            onClick={() => {
              navigate(config.routes.workflowResult.replace(":id", row._id));
            }}
          >
            {t("seeResults")}
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <Page>
      <Card
        bordered
        title={
          <Space>
            <Button
              type="link"
              icon={<ArrowLeft width={12} height={12} />}
              className="no-pad"
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>
            <Typography.Paragraph className="no-margin">
              {t("workflowHistory")}
            </Typography.Paragraph>
          </Space>
        }
      >
        <Table
          dataSource={dataSource.items}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "15", "20", "25", "30"],
            current: pagination.page,
            total: dataSource.count,
            position: ["bottomCenter"],
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              setPagination({ page, pageSize });
            },
          }}
        />
      </Card>
    </Page>
  );
};
