import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Steps,
  Table,
  Popconfirm,
} from "antd";
import { useNavigate } from "react-router-dom";

import config from "../../../config";
import useWorkflow, { FormItem } from "./useWorkflow";
import { useFetch } from "use-http";
import { useForm } from "antd/es/form/Form";
import { Clock, Edit, Play, Trash } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function WorkflowsList(): ReactElement {
  const navigate = useNavigate();
  const [isRunModalOpen, setIsRunModalOpen] = useState(false);
  const [runStep, setRunStep] = useState({
    _id: "",
    actionType: "",
    inputType: "",
    stepTitle: "",
  } satisfies FormItem & { _id: string });
  const [running, setRunning] = React.useState(false);
  const [form] = useForm();

  const { post, response, del } = useFetch(`${config.api}`);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  const { loadWorkflows, workflows } = useWorkflow();
  const { t } = useTranslation("workflow");

  const { page, pageSize } = pagination;
  useEffect(() => {
    loadWorkflows(`?skip=${(page - 1) * pageSize}&limit=${pageSize}`);
  }, [loadWorkflows, page, pageSize]);

  const run = useCallback(
    async (data: { input: string }) => {
      if (runStep._id !== "") {
        await post(`${config.apiRoutes.workflowRun}`, {
          _id: runStep._id,
          input: data.input,
        });
        setRunning(false);
        if (!response.ok) {
          if (response.status === 400) {
            message.error(
              t("validation.invalidDataInput"),
              response.data.toString()
            );
            return;
          }
        }
        message.success(t("workflowStarted"));
      }
    },
    [post, response.ok, response.status, runStep._id, t, response.data]
  );

  const deleteWorkflow = useCallback(
    async (id: string) => {
      setRunning(true);
      await del(`${config.apiRoutes.workflowDelete.replace(":id", id)}`);
      setRunning(false);
      if (response.ok) {
        message.success(t("workflowDeleted"));
        loadWorkflows(`?skip=${(page - 1) * pageSize}&limit=${pageSize}`);
        return;
      }
      message.error(t("errorAppeared"));
    },
    [del, loadWorkflows, response.ok, page, pageSize, t]
  );

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("formItems"),
      key: "formItems",
      render: (row: { formItems: Record<string, FormItem> }) => {
        const steps = [];

        for (const key in row.formItems) {
          steps.push({
            title: row.formItems[key].stepTitle,
            content: row.formItems[key].prompt,
            description: `${row.formItems[key].inputType} / ${row.formItems[key].actionType}`,
          });
        }

        return <Steps current={-1} size="small" items={steps} />;
      },
    },
    {
      title: t("action"),
      key: "action",
      render: (row: { _id: string; formItems: Record<string, FormItem> }) => (
        <Space>
          <Button
            size="large"
            type="dashed"
            icon={<Play width={12} height={12} />}
            loading={running && row._id === runStep._id}
            disabled={running && row._id !== runStep._id}
            onClick={() => {
              setIsRunModalOpen(true);
              setRunStep({ ...Object.values(row.formItems)[0], _id: row._id });
            }}
          >
            {t("run")}
          </Button>
          <Button
            size="large"
            type="dashed"
            icon={<Clock width={12} height={12} />}
            disabled={running && row._id !== runStep._id}
            onClick={() =>
              navigate(config.routes.workflowHistory.replace(":id", row._id))
            }
          >
            {t("seeHistory")}
          </Button>
          <Button
            size="large"
            type="dashed"
            icon={<Edit width={12} height={12} />}
            disabled={running && row._id !== runStep._id}
            onClick={() =>
              navigate(config.routes.workflowEdit.replace(":id", row._id))
            }
          >
            {t("edit")}
          </Button>
          <Popconfirm
            title={t("deleteTask")}
            description={t("deleteWorkflowSure")}
            onConfirm={() => {
              deleteWorkflow(row._id);
            }}
            okText={t("yes")}
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
            cancelText={t("no")}
          >
            <Button
              size="large"
              type="dashed"
              disabled={running && row._id !== runStep._id}
              icon={<Trash width={12} height={12} />}
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Modal
        title={t("runStep")}
        open={isRunModalOpen}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.submit();
              setRunning(true);
              setIsRunModalOpen(false);
            })
            .catch(() => {
              //
            });
        }}
        onCancel={() => setIsRunModalOpen(false)}
        okText={t("run")}
        okButtonProps={{
          size: "large",
        }}
        cancelButtonProps={{
          size: "large",
        }}
      >
        <Form name="runForm" layout="vertical" onFinish={run} form={form}>
          {runStep.inputType === "json" ? (
            <Form.Item
              name="input"
              label={t("jsonFile")}
              rules={[
                {
                  required: true,
                  message: t("validation.fieldRequired"),
                },
                () => ({
                  async validator(_, value) {
                    console.log({
                      value,
                    });
                    try {
                      JSON.parse(value);
                      return await Promise.resolve();
                    } catch (ex) {
                      throw new Error(t("validation.invalidFormat"));
                    }
                  },
                }),
              ]}
            >
              <Input.TextArea rows={10} size="large" />
            </Form.Item>
          ) : (
            ""
          )}
          {runStep.inputType === "text" ? (
            <Form.Item
              name="input"
              label="Text"
              rules={[
                {
                  required: true,
                  message: t("validation.fieldRequired"),
                },
              ]}
            >
              <Input.TextArea rows={10} size="large" />
            </Form.Item>
          ) : (
            ""
          )}
        </Form>
      </Modal>
      <Table
        dataSource={workflows.items}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "15", "20", "25", "30"],
          current: page,
          total: workflows.count,
          position: ["bottomCenter"],
          pageSize,
          onChange: (page, pageSize) => {
            setPagination({ page, pageSize });
          },
        }}
      />
    </>
  );
}
