import React from "react";
import { ConfigProvider } from "antd";
import Pages from "./pages/Pages";
import { UserProvider } from "./common/User/User.Context";

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Ubuntu, sans-serif",
          colorPrimary: "#ff604b",
          colorError: "#d62828",
          colorWarning: "#e9c46a",
          colorText: "black",
          borderRadius: 16,
          borderRadiusLG: 16,
          boxShadow: "0 0 0 1px #ff604b",
          colorLink: "#ff604b",
        },
      }}
    >
      <UserProvider>
        <Pages />
      </UserProvider>
    </ConfigProvider>
  );
};

export default App;
